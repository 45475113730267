import React, { useContext, useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useHomeLandingPage from 'content-queries/mx/new/home-landing-page'
import { AutomaticPopupContext } from 'context/automatic-popup/provider'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { Header, MainContent } from 'mx/components/new'
import { BasicContent } from 'mx/components/new/basic-content'
import { Card } from 'mx/components/new/card'
import { TrustedMedia } from 'mx/components/new/trusted-media'
import Layout from 'mx/layout'

const IndexPage = ({ path }: { path: string }) => {
  useEffect(() => {
    dispatch({
      type: 'popup_enabled',
      value: true,
    })
    dispatch({
      type: 'show_modal',
      value: false,
    })
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Homepage',
    })
  }, [])

  const { data } = useHomeLandingPage()
  const { dispatch } = useContext(AutomaticPopupContext)

  const [
    {
      node: {
        sectionModules: [
          hero,
          media,
          design,
          perform,
          engineered,
          pricing,
          kitchenCards,
        ],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <Header data={hero} addTransparency switchHeader />
      <TrustedMedia data={media} classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={design} inline classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={perform} dark classname={tw`mt-20 lg:mt-32`} />
      <MainContent data={engineered} inline classname={tw`mt-20 lg:mt-32`} />
      <BasicContent
        data={pricing}
        isCenterAligned
        classname={tw`mt-20 lg:mt-32`}
      />
      <Card data={kitchenCards} singleText classname={tw`my-12 lg:my-20`} />
    </Layout>
  )
}

export default IndexPage

export const Head = () => {
  const { data } = useHomeLandingPage()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  const meta: [{ name: string; content: string }?] =
    process.env.GATSBY_ACTIVE_ENV === 'production'
      ? [
          {
            name: `p:domain_verify`,
            content: '326031e6a77efa67405cff2d7dc2b561',
          },
        ]
      : []
  return (
    <SEO
      description={description}
      titleOverride={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
      meta={meta}
    />
  )
}
